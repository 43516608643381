const lang_arr = {
  EN: "ENG",
  AU: "ENG",
  IE: "ENG",
  NZ: "ENG",
  ZA: "ENG",
  DE: "GER",
  SE: "SWE",
  SV: "SWE",
  RU: "RUS",
  ES: "SPA",
  FR: "FRE",
  BE: "FRE",
  DK: "DAN",
  DA: "DAN",
  IT: "ITA",
  NL: "DUT",
  FI: "FIN",
  UK: "ENG",
  PT: "POR",
  BR: "BRA",
  JP: "JPN",
  HU: "HUN",
};

export const getCountry = () => {
  try {
    let country = readAdvCookie("country");
    return country || "";
  } catch (error) {
    console.log("error: ", error);
    return "";
  }
};

export const getCity = () => {
  try {
    let country = readAdvCookie("city");
    return country || "";
  } catch (error) {
    console.log("error: ", error);
    return "";
  }
};

const getDefaultFooter = (bonus, deposit, allowedGame) => (
  <>
    <p>
      18+. New Players Only. Terms and Conditions Apply. Available only on{" "}
      {allowedGame} game. Min. Deposit $/€{deposit}. Offer valid for 24 hrs. No
      wagering required. See full terms here. Min. Deposit: $/€
      {deposit} Max. Bonus: {bonus} Bonus Spins. Offer valid on first deposit.
      Offer only applies to new players. Spins are given as follows: {bonus}{" "}
      Spins upon a first deposit of minimum $/€{deposit}. Spins may only be used
      in specific games. The game allowed: {allowedGame}. No wagering is
      required. In order to withdraw your winnings, you must first use up all of
      your bonus spins or wait for them to expire (whichever comes first). o
      Bonus Policy and Terms of Service apply. The “Welcome bonus” is limited to
      one bonus per household. The “Welcome bonus” may not be used in
      conjunction with any other bonus, promotion or offer. The “Welcome bonus”
      must be wagered at Magicred.com within 1 day of being credited to your
      account. Magicred.com reserves the right, at any time, to revoke any
      welcome bonus not used within the allotted time period. Unused free games
      will be removed from the account thereafter.
    </p>
    <br />
    <p>
      Copyright 2022 - All Rights Reserved. The games on this website are
      powered and operated by Aspire Global International LTD., a Malta based
      company with registration number C42296 and having registered office at
      135, High street, Sliema SLM 1549, Malta, which is a fully licensed
      operator under the Remote Gaming Regulations of Malta (MGA/CL1/408/2007
      issued on the 17 August 2009, MGA/CL1/876/2013 issued on the 19 September
      2013, MGA/CL1/1000/2014 issued on the 20th of August 2014,
      MGA/CL1/1142/2015 issued on the 26th January 2016, MGA/CL1/1174/2016
      issued on the 26th May 2016) and regulated by the Malta Gaming Authority.
      In Great Britain only, the games on this website are operated by AG
      Communications Limited, a Malta based company with registration number
      C48328 and having registered office at 135, High street, Sliema SLM 1549,
      Malta, which is licensed and regulated by the United Kingdom Gambling
      Commission (Remote Gaming License Number 000-039483-R-319409-001).The
      current status of operators license can be found{" "}
      <a
        target="_blank"
        href="https://secure.gamblingcommission.gov.uk/gccustomweb/PublicRegister/PRSearch.aspx?ExternalAccountId=39483"
      >
        {" "}
        by clicking here
      </a>
    </p>
  </>
);

const ontarioCities = [
  "Toronto",
  "Ottawa",
  "Mississauga",
  "Brampton",
  "Hamilton",
  "London",
  "Markham",
  "Vaughan",
  "Kitchener",
  "Windsor",
  "Richmond Hill",
  "Burlington",
  "Oshawa",
  "Greater Sudbury",
  "Barrie",
  "Guelph",
  "Cambridge",
  "St. Catharines",
  "Waterloo",
  "Thunder Bay",
  "Brantford",
  "Pickering",
  "Niagara Falls",
  "Peterborough",
  "Sault Ste. Marie",
  "Sarnia",
  "Norfolk County",
  "Welland",
  "Belleville",
  "North Bay",
];

export const states = {
  ONTARIO: "Ontario",
  DEFAULT: "default",
};

export const currencies = {
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
  MGA: "MGA",
  DEFAULT: "default",
};

export const currencyIcons = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  MGA: "$/€",
  DEFAULT: "default",
};

export const countries = {
  DEFAULT: "default",
};

export const getDataByCountry = (
  bonus = 20,
  deposit = 10,
  allowedGame = "Big Bass Bonanza"
) => {
  const country = getCountry();
  const city = getCity();

  let lang = "";
  let currency = "";
  let mainSite = "";
  let state = states.DEFAULT;
  let footertext = "";

  switch (country) {
    case "GB":
      lang = "EN";
      currency = "GBP";
      mainSite = "https://www.magicred.com/";
      footertext = (
        <p>
          18+. New Players Only. Terms and Conditions Apply. Available only on{" "}
          {allowedGame} game. Min. Deposit £{deposit}. Offer valid for 24 hrs.
          No wagering required. See full terms here. Min. Deposit: £{deposit}{" "}
          Max. Bonus: {bonus} Bonus Spins. Offer valid on first deposit. Offer
          only applies to new players. Spins are given as follows: {bonus} Spins
          upon a first deposit of minimum £{deposit}. Spins may only be used in
          specific games. The game allowed: {allowedGame}. No wagering is
          required. In order to withdraw your winnings, you must first use up
          all of your bonus spins or wait for them to expire (whichever comes
          first). o Bonus Policy and Terms of Service apply. The “Welcome bonus”
          is limited to one bonus per household. The “Welcome bonus” may not be
          used in conjunction with any other bonus, promotion or offer. The
          “Welcome bonus” must be wagered at Magicred.com within 1 day of being
          credited to your account. Magicred.com reserves the right, at any
          time, to revoke any welcome bonus not used within the allotted time
          period. Unused free games will be removed from the account thereafter.
          In Great Britain, the games on this website are operated by AG
          Communications Limited, a Malta-based company with registration number
          C48328 and having registered office at 135, High street, Sliema SLM
          1549, Malta, which is licensed and regulated by the United Kingdom
          Gambling Commission (Remote Gaming License Number
          000-039483-R-319409-001). The current status of operators license can
          be found at Gambling Commission. Bonus Policy and Terms of Service
          apply.
        </p>
      );
      break;
    case "NZ":
      lang = "EN";
      currency = "USD";
      mainSite = "https://www.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "IE":
      lang = "EN";
      currency = "EUR";
      mainSite = "https://www.magicred.com/";
      footertext = getDefaultFooter(bonus);
      break;
    case "ZA":
      lang = "EN";
      currency = "ZAR";
      mainSite = "https://www.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "SE":
      lang = "SE";
      currency = "SEK";
      mainSite = "https://sv.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "HU":
      lang = "HU";
      currency = "EUR";
      mainSite = "https://www.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "BR":
      lang = "BR";
      currency = "BRL";
      mainSite = "https://br.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "CH":
    case "AT":
    case "DE":
      lang = "DE";
      currency = "EUR";
      mainSite = "https://de.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "FI":
      lang = "FI";
      currency = "EUR";
      mainSite = "https://fi.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "CA":
      lang = "EN";
      currency = "USD";
      state = ontarioCities.includes(city) ? states.ONTARIO : null;
      mainSite = "https://www.magicred.ca/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "AU":
      lang = "EN";
      currency = "USD";
      mainSite = "https://www.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "MX":
    case "ES":
    case "CL":
    case "AR":
    case "PE":
    case "VE":
    case "UY":
    case "EC":
    case "GT":
    case "NI":
    case "PA":
    case "HN":
      lang = "ES";
      currency = "USD";
      mainSite = "https://es.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    case "DK":
      lang = "DK";
      currency = "DKK";
      mainSite = "https://www.magicred.dk/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
    default:
      lang = "EN";
      currency = "MGA";
      mainSite = "https://www.magicred.com/";
      footertext = getDefaultFooter(bonus, deposit, allowedGame);
      break;
  }

  let currenyIcon = "$/€";
  switch (currency) {
    case "USD":
      currenyIcon = "$";
      break;
    case "EUR":
      currenyIcon = "€";
      break;
    case "GBP":
      currenyIcon = "£";
    default:
      break;
  }

  return {
    lang,
    currency,
    mainSite,
    country,
    footertext,
    state,
    currenyIcon,
  };
};

export let getTranslations = ({ lang, footertext }) => {
  let lib = {
    EN: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "JOIN HERE",
      step_1_description: "Quick & Easy",
      step_2_title: "DEPOSIT",
      step_2_description: "Claim your Exclusive Bonus",
      step_3_title: "PLAY",
      step_3_description: "Enjoy the best Casino Games",
      footer_text: footertext,
    },
    DE: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "ANMELDEN",
      step_1_description: "Schnell und einfach",
      step_2_title: "EINZAHLEN",
      step_2_description: "Fordern Sie Ihren exklusiven Bonus",
      step_3_title: "SPIELEN",
      step_3_description: "Kein Herunterladen erforderlich",
      footer_text: footertext,
      // footer_text: 'Copyright 2022- All Rights Reserved. The games on this website are powered and operated by Aspire Global International LTD., a Malta based company with registration number C42296 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is a fully licensed operator under the Remote Gaming Regulations of Malta (MGA/CL1/408/2007 issued on the 17 August 2009, MGA/CL1/876/2013 issued on the 19 September 2013, MGA/CL1/1000/2014 issued on the 20th of August 2014, MGA/CL1/1142/2015 issued on the 26th January 2016, MGA/CL1/1174/2016 issued on the 26th May 2016) and regulated by the Malta Gaming Authority. In Great Britain only, the games on this website are operated by AG Communications Limited, a Malta based company with registration number C48328 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is licensed and regulated by the United Kingdom Gambling Commission (Remote Gaming License Number 000-039483-R-319409-001).The current status of operators license can be found <a target="_blank" href="https://secure.gamblingcommission.gov.uk/gccustomweb/PublicRegister/PRSearch.aspx?ExternalAccountId=39483"> by clicking here</a>',
    },
    BR: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "REGISTRE-SE",
      step_1_description: "Rápido e fácil",
      step_2_title: "DEPOSITE",
      step_2_description: "E receba seu bônus exclusivo",
      step_3_title: "JOGUE",
      step_3_description: "Aproveite os melhores jogos de cassino",
      footer_text: footertext,
      // footer_text: 'Copyright 2022- All Rights Reserved. The games on this website are powered and operated by Aspire Global International LTD., a Malta based company with registration number C42296 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is a fully licensed operator under the Remote Gaming Regulations of Malta (MGA/CL1/408/2007 issued on the 17 August 2009, MGA/CL1/876/2013 issued on the 19 September 2013, MGA/CL1/1000/2014 issued on the 20th of August 2014, MGA/CL1/1142/2015 issued on the 26th January 2016, MGA/CL1/1174/2016 issued on the 26th May 2016) and regulated by the Malta Gaming Authority. In Great Britain only, the games on this website are operated by AG Communications Limited, a Malta based company with registration number C48328 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is licensed and regulated by the United Kingdom Gambling Commission (Remote Gaming License Number 000-039483-R-319409-001).The current status of operators license can be found <a target="_blank" href="https://secure.gamblingcommission.gov.uk/gccustomweb/PublicRegister/PRSearch.aspx?ExternalAccountId=39483"> by clicking here</a>',
    },
    HU: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "Csatlakozzon most",
      step_1_description: "Gyorsan és egyszerűen",
      step_2_title: "Fizessen be",
      step_2_description: "Gyűjtse be az exkluzív bónuszokat",
      step_3_title: "Játsszon",
      step_3_description: "És élvezze a legjobb kaszinó játékokat!",
      footer_text: footertext,
      // footer_text: 'Szerzői jog 2022 - Minden jog fenntartva. A weboldalon található játékok üzemeltetését és fenntartását az Aspire Global International LTD. végzi amely egy máltai székhelyű vállalat. Regisztrációs száma C42296, székhelye: 135, High Street, Sliema SLM 1549, Málta. A cég a máltai Remote Gaming Regulations, vagyis a Távoli Játékokra vonatkozó szabályozásnak megfelelően működik (szabályozások száma: MGA / CL1 / 408/2007, közzététel 2009. augusztus 17-én, MGA / CL1 / 876/2013, közzététel 2013. szeptember 19-én, MGA / CL1 / 1000/2014, közzététel 2014. augusztus 20, MGA / CL1 / 1142/2015, közzététel 2016. január 26-án kiadott, MGA / CL1 / 1174/2016, közzététel 2016. május 26-án). A vállalat  a Máltai Szerencsejáték-Hatóság szabályozása alatt áll. Csak Nagy-Britanniára vonatkozóan, az ezen a weboldalon található játékokat az AG Communications Limited üzemelteti, amely egy máltai székhelyű cég. Regisztrációs szám: C48328,  székhely: High Street, Sliema SLM 1549, Málta. A vállalat működését az Egyesült Királyság Szerencsejáték Bizottsága  engedélyezi és szabályozza (Remote Gaming Licenc száma 000-039483-R-319409-001). A szolgáltatói engedély jelenlegi állapota megtalálható',
    },
    SE: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "REGISTRERA DIG",
      step_1_description: "Snabbt och enkelt",
      step_2_title: "SÄTT IN PENGAR",
      step_2_description: "Hämta ut din exklusiva bonus",
      step_3_title: "SPELA",
      step_3_description: "Ingen nerladdning behövs",
      footer_text: footertext,
      // footer_text: 'Copyright 2022- All Rights Reserved. The games on this website are powered and operated by Aspire Global International LTD., a Malta based company with registration number C42296 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is a fully licensed operator under the Remote Gaming Regulations of Malta (MGA/CL1/408/2007 issued on the 17 August 2009, MGA/CL1/876/2013 issued on the 19 September 2013, MGA/CL1/1000/2014 issued on the 20th of August 2014, MGA/CL1/1142/2015 issued on the 26th January 2016, MGA/CL1/1174/2016 issued on the 26th May 2016) and regulated by the Malta Gaming Authority. In Great Britain only, the games on this website are operated by AG Communications Limited, a Malta based company with registration number C48328 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is licensed and regulated by the United Kingdom Gambling Commission (Remote Gaming License Number 000-039483-R-319409-001).The current status of operators license can be found <a target="_blank" href="https://secure.gamblingcommission.gov.uk/gccustomweb/PublicRegister/PRSearch.aspx?ExternalAccountId=39483"> by clicking here</a>',
    },
    FI: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "LIITY NYT",
      step_1_description: "Nopea ja helppo",
      step_2_title: "TALLETTAA",
      step_2_description: "Pyydä yksinomainen bonus",
      step_3_title: "PELATA",
      step_3_description: "Ei tarvitse ladata",
      footer_text: footertext,
      // footer_text: 'Copyright 2022- All Rights Reserved. The games on this website are powered and operated by Aspire Global International LTD., a Malta based company with registration number C42296 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is a fully licensed operator under the Remote Gaming Regulations of Malta (MGA/CL1/408/2007 issued on the 17 August 2009, MGA/CL1/876/2013 issued on the 19 September 2013, MGA/CL1/1000/2014 issued on the 20th of August 2014, MGA/CL1/1142/2015 issued on the 26th January 2016, MGA/CL1/1174/2016 issued on the 26th May 2016) and regulated by the Malta Gaming Authority. In Great Britain only, the games on this website are operated by AG Communications Limited, a Malta based company with registration number C48328 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is licensed and regulated by the United Kingdom Gambling Commission (Remote Gaming License Number 000-039483-R-319409-001).The current status of operators license can be found <a target="_blank" href="https://secure.gamblingcommission.gov.uk/gccustomweb/PublicRegister/PRSearch.aspx?ExternalAccountId=39483"> by clicking here</a>',
    },
    ES: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "ÚNETE AHORA",
      step_1_description: "Rápido y Fácil",
      step_2_title: "DEPOSITA",
      step_2_description: "Obtén tu Bono Exclusivo",
      step_3_title: "JUEGA",
      step_3_description: "Disfruta de Juegos de Casino",
      footer_text: footertext,
      // footer_text: 'Copyright 2022- All Rights Reserved. The games on this website are powered and operated by Aspire Global International LTD., a Malta based company with registration number C42296 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is a fully licensed operator under the Remote Gaming Regulations of Malta (MGA/CL1/408/2007 issued on the 17 August 2009, MGA/CL1/876/2013 issued on the 19 September 2013, MGA/CL1/1000/2014 issued on the 20th of August 2014, MGA/CL1/1142/2015 issued on the 26th January 2016, MGA/CL1/1174/2016 issued on the 26th May 2016) and regulated by the Malta Gaming Authority. In Great Britain only, the games on this website are operated by AG Communications Limited, a Malta based company with registration number C48328 and having registered office at 135, High street, Sliema SLM 1549, Malta, which is licensed and regulated by the United Kingdom Gambling Commission (Remote Gaming License Number 000-039483-R-319409-001).The current status of operators license can be found <a target="_blank" href="https://secure.gamblingcommission.gov.uk/gccustomweb/PublicRegister/PRSearch.aspx?ExternalAccountId=39483"> by clicking here</a>',
    },
    DK: {
      site_title: "MagicRed.com - Online casino",
      step_1_title: "TILMELD DIG HER",
      step_1_description: "Hurtigt og nemt",
      step_2_title: "INDBETALING",
      step_2_description: "Gør krav på din eksklusive bonus",
      step_3_title: "SPIL",
      step_3_description: "Nyd de bedste casinospil",
      footer_text: footertext,
    },
  };

  return lib?.[lang] || lib.EN;
};

export const convert_reg_lang_to_aspire_lang = (lang_in) => {
  let lang = lang_in.toUpperCase();
  let lang_out = Object.keys(lang_arr).find((el) => el === lang)
    ? lang_arr[lang]
    : "ENG";
  return lang_out;
};

const readAdvCookie = (name) => {
  const nameEQ = name + "=";
  const nDecoded = decodeURIComponent(document.cookie);
  const ca = nDecoded.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const getColorByPath = (pathname) => {
  switch (pathname) {
    case "/welcome_lp":
      return "#241F20";
    default:
      return "#000";
  }
};
