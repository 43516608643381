import { useEffect } from 'react';

const WithScript = () => {
	useEffect(() => {
		window.top.document.title = 'magicred.com';
		window.location.href =
			'https://nerve.eshkol.io/go' + window.location.search;
	}, []);

	return null;
};

export default WithScript;
